import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import $$ from '../../utils/className'
import styles from './index.module.scss'

export type FrameAction = {
  name: string;
  title: string | JSX.Element;
  onClick: () => void;
}

export type FrameProps = {
  children: React.ReactNode;
  title?: string;
  className?: string;
  onScrollEnd?: () => void;
  actions?: Array<FrameAction>;
}

function Frame (props: FrameProps & RouteComponentProps) {
  const handleBack = () => {
    props.history.goBack()
  }
  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLDivElement
    if (props.onScrollEnd && target.scrollTop + target.clientHeight === target.scrollHeight) {
      props.onScrollEnd()
    }
  }
  return (
    <div className={styles.whole}>
      <div className={styles.header}>
        <div className={styles.back} onClick={handleBack}>
          <span className="material-icons">arrow_back</span>
          {props.title}
        </div>
        <div className={styles.actions}>
          {props.actions?.map(action => (
            <div key={action.name} title={action.name} className={styles.action} onClick={action.onClick}>
              {action.title}
            </div>
          ))}
        </div>
      </div>
      <div className={$$([styles.content, props.className])} onScroll={handleScroll}>
        {props.children}
      </div>
    </div>
  )
}

export default withRouter(Frame)
