import history from './history'

export type Body<T = any> = {
  content: T;
  success: boolean;
  message: string;
}

export type Response<T = any> = Promise<Body<T>>

const server = '/api'

function request (url: string, data: any = {}) {
  const headers = new Headers()
  const token = window.localStorage.getItem('token')
  if (token) {
    headers.append('authorization', 'JWT ' + token)
  }
  headers.append('Content-Type', 'application/json')
  data = JSON.stringify(data)
  return fetch(server + url, {
    method: 'POST',
    body: data,
    headers
  }).then(res => res.json() as Response, () => ({ success: false, content: {}, message: '网络错误，请重新登录或联系管理员！' }))
  .then(res => {
    if (!res.success && res.message.indexOf('重新登录') >= 0) {
      history.push('/login')
    }
    return res
  })
}

export default request
