import { useEffect, useRef, useState } from 'react'
import AmountInput from '../../../components/AmountInput'
import MyButton from '../../../components/MyButton'
import __ from '../../../components/MyMessage'
import MyModal, { MyModalRef } from '../../../components/MyModal'
import { Bill } from '../../../utils/entity'
import Service from '../../../utils/service'
import { ts2string } from '../../../utils/time'
import styles from './index.module.scss'
import $$ from '../../../utils/className'

export type BillCardProps = {
  onClick?: (id: string) => void;
  onUpdate?: (id: string) => void;
} & Bill

function BillCard (props: BillCardProps) {
  const [show, setShow] = useState(false)
  const [amount, setAmount] = useState(0)
  const [updating, setUpdating] = useState(false)
  const confirmRef = useRef<MyModalRef>(null)
  const cdRef = useRef<MyModalRef>(null)
  useEffect(() => {
    setAmount(props.true_amount || props.amount)
  }, [props.true_amount, props.amount])
  const handleConfirm = () => {
    confirmRef.current?.open()
  }
  const handleConfirmTwice = () => {
    (updating ? Service.bill.update : Service.bill.confirm)({ id: props.id, true_amount: amount }).then(res => {
      if (res.success) {
        setUpdating(false)
        props.onUpdate && props.onUpdate(props.id)
      } else {
        __(res.message)
      }
    })
  }
  const handleCD = () => {
    cdRef.current?.open()
  }
  const handleCDTwice = () => {
    (props.canceled ? Service.bill.delete : Service.bill.cancel)({ id: props.id }).then(res => {
      if (res.success) {
        props.onUpdate && props.onUpdate(props.id)
        __(`${props.canceled ? '删除' : '取消'}成功！`)
      } else {
        __(res.message)
      }
    })
  }
  return (
    <div className={$$([styles.bill, props.canceled && styles.canceled])} onClick={() => {
      props.onClick && props.onClick(props.id)
      setShow(!show)
    }}>
      <div className={styles.brief}>
        <div className={styles.left}>
          <div className={styles.title}>{props.title}</div>
          <div className={styles.time}>{ts2string(props.pay_time)}</div>
          {show && <>
            {props.prepaid && props.prepay_confirmed && <div className={styles.time}>{ts2string(props.true_pay_time)} 已确认</div>}
            {props.canceled && <div className={styles.time}>{ts2string(props.cancel_time)} 已取消</div>}
          </>}
        </div>
        <div className={styles.right}>
          <div className={styles.amount}>-{(props.true_amount || props.amount) / 100}</div>
          {props.prepaid && <div className={styles.prepaid}>{props.prepay_confirmed ? `预付 ${props.amount / 100} 元` : `待确认`}</div>}
        </div>
      </div>
      {show && (
        <div className={styles.detail} onClick={e => e.stopPropagation()}>
          {props.tags.length > 0 && <div className={styles.tags}>标签：{props.tags.join(',')}</div>}
          {props.description && <div className={styles.tags}>描述：{props.description}</div>}
          {!props.canceled && (updating || (props.prepaid && !props.prepay_confirmed)) && <>
            <AmountInput value={amount} onChange={setAmount} calc />
            <MyButton title="确认" onClick={handleConfirm} />
            <MyModal ref={confirmRef} onOk={handleConfirmTwice}>
              确定是实际花费了 {amount / 100} 元吗？
            </MyModal>
          </>}
          {!updating && !props.canceled && (!props.prepaid || (props.prepaid && props.prepay_confirmed)) && <MyButton title="修改金额" onClick={() => setUpdating(true)} />}
          {props.canceled ? <MyButton title="删除此账单" onClick={handleCD} /> : <MyButton title="取消此账单" onClick={handleCD} />}
          <MyModal ref={cdRef} onOk={handleCDTwice}>
            确定要{props.canceled ? '删除' : '取消'}此账单吗？
          </MyModal>
        </div>
      )}
    </div>
  )
}

export default BillCard
