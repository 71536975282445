import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import $$ from '../../utils/className'
import styles from './index.module.scss'

export type MyInputProps = {
  placeholder?: string;
  onChange?: (v: string) => void;
  onEnter?: (v: string) => void;
  type?: 'text' | 'number' | 'password';
  value?: string;
  small?: boolean;
  disabled?: boolean;
  textNumber?: boolean;
}

function MyInput (props: MyInputProps) {
  const [value, setValue] = useState('')
  useEffect(() => {
    setValue(props.value || '')
  }, [props.value])
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(value)
    props.onChange && props.onChange(value)
  }
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      props.onEnter && props.onEnter(value)
    }
  }
  return (
    <div className={$$([styles.whole, props.small && styles.small])}>
      <input
        className={$$([(props.type === 'number' || props.textNumber) && 'number', props.textNumber && styles.textNumber])}
        type={props.type ?? 'text'}
        placeholder={props.placeholder}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        value={value}
        disabled={props.disabled}
      />
    </div>
  )
}

export default MyInput
