import React, { useState } from 'react'
import { RouterProps, withRouter } from 'react-router'
import AmountInput from '../../components/AmountInput'
import MyButton from '../../components/MyButton'
import MyCheckbox from '../../components/MyCheckbox'
import MyInput from '../../components/MyInput'
import __ from '../../components/MyMessage'
import Summary, { SummaryDate } from '../../components/Summary'
import TagInput from '../../components/TagInput'
import Service from '../../utils/service'
import styles from './index.module.scss'

const SummaryInHome: Array<SummaryDate> = ['today', 'this_month']

function Home (props: RouterProps) {
  const [title, setTitle] = useState('')
  const [amount, setAmount] = useState(0)
  const [tags, setTags] = useState<Array<string>>([])
  const [description, setDescription] = useState('')
  const [prepaid, setPrepaid] = useState(false)
  const [calc, setCalc] = useState(false)
  const handleSubmit = () => {
    if (!title && !tags.length) {
      __('好歹写点点东西说说钱怎么花的吧？')
      return
    }
    if (!amount) {
      __('没花钱记啥呢？')
      return
    }
    Service.bill.add({ title, amount, tags, description, prepaid }).then(res => {
      if (res.success) {
        props.history.push('/bill')
      } else {
        __(res.message)
      }
    })
  }
  const handleTabClick = (url: string) => {
    return () => props.history.push(url)
  }
  return (
    <div className={styles.whole}>
      <div className={styles.text}>
        <MyInput placeholder="标题" value={title} onChange={setTitle} small />
        <MyInput placeholder="描述" value={description} onChange={setDescription} small />
      </div>
      <TagInput onChange={setTags} />
      <AmountInput onChange={setAmount} calc={calc} />
      <div className={styles.options}>
        <MyCheckbox value={prepaid} onChange={setPrepaid}>预付费</MyCheckbox>
        <MyCheckbox value={calc} onChange={setCalc}>运算</MyCheckbox>
      </div>
      <MyButton title="记 账" onClick={handleSubmit} />
      <Summary value={SummaryInHome} onClick={handleTabClick('/statistic')} />
      <div className={styles.tab}>
        <MyButton title="账单" onClick={handleTabClick('/bill')} />
        <MyButton title="统计" onClick={handleTabClick('/statistic')} />
        <MyButton title="用户" onClick={handleTabClick('/user')} />
      </div>
    </div>
  )
}

export default withRouter(Home)
