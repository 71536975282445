import $$ from '../../../utils/className'
import { getDayOfWeek, MS_PER_DAY } from '../../../utils/time'
import CalendarAmount from '../CalendarAmount'
import styles from './index.module.scss'

export type CalendarEntryProps = {
  base: number;
  offset: number;
  week?: boolean;
  onClick?: (begin: number, end: number) => void;
}

const getMonthDay = (timestamp: number) => {
  return new Date(timestamp).toLocaleDateString().split('/').slice(1).join(' 月 ') + ' 日'
}

function CalendarEntry (props: CalendarEntryProps) {
  const currentTimestamp = new Date().getTime()
  const { base, offset, week } = props
  const beginTimestamp = base + MS_PER_DAY * offset
  const endTimestamp = beginTimestamp + (week ? 7 : 1) * MS_PER_DAY
  const compare = currentTimestamp >= endTimestamp ? -1 : currentTimestamp < beginTimestamp ? 1 : 0
  const day = compare === 0 ? (getDayOfWeek(currentTimestamp) + 1) : 7
  const handleClick = () => {
    props.onClick && props.onClick(beginTimestamp, endTimestamp)
  }
  const text = week ? `${getMonthDay(beginTimestamp)} - ${getMonthDay(endTimestamp - 1)}` : new Date(beginTimestamp).getDate()
  return (
    <div className={$$([styles.whole, compare === 0 && styles.today, week && styles.week])} onClick={handleClick}>
      <div className={styles.day}>
        { text }
      </div>
      <CalendarAmount begin={beginTimestamp} end={endTimestamp} day={week ? day : 0} disabled={compare > 0} short={!week} />
    </div>
  )
}

export default CalendarEntry
