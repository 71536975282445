import { useState } from 'react'
import $$ from '../../utils/className'
import styles from './index.module.scss'

export type MyCheckboxProps = {
  value?: boolean;
  onChange?: (value: boolean) => void;
  children?: React.ReactNode;
}

function MyCheckbox (props: MyCheckboxProps) {
  const [value, setValue] = useState(false)
  const handleClick = () => {
    const newValue = !value
    setValue(newValue)
    props.onChange && props.onChange(newValue)
  }
  return (
    <div className={$$([styles.whole, value && styles.checked])} onClick={handleClick}>
      <div className={styles.check}>
        <span className="material-icons">{value ? 'done' : 'close'}</span>
      </div>
      <div className={styles.title}>
        {props.children}
      </div>
    </div>
  )
}

export default MyCheckbox
