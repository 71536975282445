
export const BIG_MONTH = [1, 3, 5, 7, 8, 10, 12]
export const SMALL_MONTH = [4, 6, 9, 11]
export const CHINESE_DAYS = Array.from('一二三四五六日')
export const S_PER_DAY = 60 * 60 * 24
export const MS_PER_DAY = 1000 * S_PER_DAY
export const MS_S_TIMESTAMP_LINE = 10000000000

export const getDateString = (t: string | number = 0) => {
  return (t ? new Date(t) : new Date()).toLocaleDateString().split('/').map(v => v.length === 1 ? ('0' + v) : v).join('-')
}

export const ts2string = (ts: number) => {
  return new Date(ts * 1000).toLocaleString('chinese', { hour12: false })
}

export const ts2date = (ts: number) => {
  return new Date(ts * 1000).toLocaleDateString()
}

export const getDayOfWeek = (dateTimeString?: string | number) => {
  const date = dateTimeString ? new Date(dateTimeString) : new Date()
  const dayOfWeek = (date.getDay() + 6) % 7
  return dayOfWeek
}

export const getDaysOfMonth = (year: number, month: number) => {
  if (BIG_MONTH.includes(month)) {
    return 31
  } else if (SMALL_MONTH.includes(month)) {
    return 30
  }
  if (year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0)) {
    return 29
  }
  return 28
}

export const ms2s = ({ begin, end }: { begin: number, end: number }) => {
  if (begin < MS_S_TIMESTAMP_LINE && end < MS_S_TIMESTAMP_LINE) {
    return { begin, end }
  }
  return {
    begin: begin / 1000 | 0,
    end: end / 1000 | 0
  }
}

const _today = () => {
  const date = new Date()
  const begin = new Date(date.toDateString()).getTime()
  return {
    begin,
    end: begin + MS_PER_DAY
  }
}

const _yesterday = () => {
  const { begin, end } = _today()
  return {
    begin: begin - MS_PER_DAY,
    end: end - MS_PER_DAY
  }
}

const _currentWeek = () => {
  const date = new Date()
  const dayOfWeek = getDayOfWeek()
  const dayTimestamp = new Date(date.toLocaleDateString()).getTime()
  return {
    begin: dayTimestamp - dayOfWeek * MS_PER_DAY,
    end: dayTimestamp + (7 - dayOfWeek) * MS_PER_DAY
  }
}

const _lastWeek = () => {
  const { begin, end } = _currentWeek()
  return {
    begin: begin - MS_PER_DAY * 7,
    end: end - MS_PER_DAY * 7
  }
}

const _currentMonth = () => {
  const date = new Date()
  const month1 = date.getMonth() + 1
  const year1 = date.getFullYear()
  const month2 = month1 === 12 ? 1 : (month1 + 1)
  const year2 = month1 === 12 ? (year1 + 1) : year1
  return {
    begin: new Date(`${year1}/${month1}/1`).getTime(),
    end: new Date(`${year2}/${month2}/1`).getTime()
  }
}

const _lastMonth = () => {
  const date = new Date()
  const month1 = date.getMonth() + 1
  const year1 = date.getFullYear()
  const month2 = month1 === 1 ? 12 : (month1 - 1)
  const year2 = month1 === 1 ? (year1 - 1) : year1
  return {
    begin: new Date(`${year2}/${month2}/1`).getTime(),
    end: new Date(`${year1}/${month1}/1`).getTime()
  }
}

export const today = () => ms2s(_today())
export const yesterday = () => ms2s(_yesterday())
export const currentWeek = () => ms2s(_currentWeek())
export const lastWeek = () => ms2s(_lastWeek())
export const currentMonth = () => ms2s(_currentMonth())
export const lastMonth = () => ms2s(_lastMonth())
