import { Switch, Route } from 'react-router-dom'
import Home from '../pages/Home'
import Bill from '../pages/Bill'
import Login from '../pages/Login'
import User from '../pages/User'
import Statistic from '../pages/Statistic'

export const routes = [
  {
    name: '首页',
    path: '/',
    component: Home
  },
  {
    name: '账单',
    path: '/bill',
    component: Bill
  },
  {
    name: '统计',
    path: '/statistic',
    component: Statistic
  },
  {
    name: '用户',
    path: '/user',
    component: User
  },
  {
    name: '登录',
    path: '/login',
    component: Login
  }
]

function Router () {
  return (
    <Switch>
      {routes.map(route => <Route exact path={route.path} component={route.component} key={route.name}></Route>)}
    </Switch>
  )
}

export default Router
