import { ChangeEvent } from 'react'
import { getDateString } from '../../utils/time'
import styles from './index.module.scss'

type MyDateInputProps = {
  value?: number;
  onChange?: (value: number) => void;
}

const MyDateInput = (props: MyDateInputProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (value) {
      props.onChange && props.onChange((new Date(value).getTime() / 1000 | 0) - 8 * 3600)
    }
  }
  const value = props.value ? getDateString(props.value * 1000 + 8 * 3600000) : 0
  return (
    <div className={styles.whole}>
      <input type="date" className={styles.date} value={value} onChange={handleChange} />
    </div>
  )
}

export default MyDateInput
