import { useEffect, useState } from 'react'
import MyButton from '../../components/MyButton'
import MyInput from '../../components/MyInput'
import styles from './index.module.scss'

export type AmountInputProps = {
  value?: number;
  calc?: boolean;
  onChange?: (amount: number) => void;
}

const operators = '+-*/'
const valueAmount = (value: string) => {
  const dot = value.indexOf('.')
  if (dot < 0) {
    value = value + '.00'
  } else if (dot === value.length - 1) {
    value += '00'
  } else if (dot === value.length - 2) {
    value += '0'
  }
  const [left, right] = value.split('.')
  return parseInt(left + '00') + parseInt(right)
}

function AmountInput (props: AmountInputProps) {
  const keys = Array.from('C123=456.7890' + (props.calc ? operators : ''))
  const [value, setValue] = useState('0')
  const [operand, setOperand] = useState(0)
  const [operator, setOperator] = useState(-1)
  useEffect(() => {
    setValue(((props.value || 0) / 100).toString())
  }, [props.value])
  const changeValue = (value: string) => {
    setValue(value)
    props.onChange && props.onChange(valueAmount(value))
  }
  const handleBackspace = () => {
    if (value.length > 1) {
      changeValue(value.substring(0, value.length - 1))
    } else {
      changeValue('0')
    }
  }
  const handleKeyboardClick = (key: string) => {
    if (key === 'C') {
      return () => {
        changeValue('0')
        setOperator(-1)
      }
    }
    if (key === '.') {
      return () => value.indexOf(key) < 0 && changeValue(value + key)
    }
    const getResult = () => {
      const operand2 = valueAmount(value)
      let result = operand
      switch (operator) {
        case 0:
          result += operand2
          break
        case 1:
          result -= operand2
          break
        case 2:
          result *= operand2
          result /= 100
          break
        case 3:
          result /= operand2 === 0 ? 1 : operand2
          result *= 100
          break
      }
      return result
    }
    if (key === '=') {
      return () => {
        if (operator === -1) return
        let value = (getResult() / 100).toFixed(2)
        while (value.endsWith('0')) {
          value = value.substring(0, value.length - 1)
        }
        if (value.endsWith('.')) value = value.substring(0, value.length - 1)
        changeValue(value)
        setOperator(-1)
      }
    }
    if (operators.includes(key)) {
      return () => {
        if (operator === -1) {
          setOperand(valueAmount(value))
        } else {
          setOperand(getResult())
        }
        changeValue('0')
        setOperator(operators.indexOf(key))
      }
    }
    return () => {
      const dot = value.indexOf('.')
      if (dot < 0 || (dot >= value.length - 2)) {
        changeValue(value === '0' ? key : (value + key))
      }
    }
  }
  return (
    <div className={styles.whole} onClick={e => e.stopPropagation()}>
      <MyInput textNumber value={value} onChange={changeValue} disabled={true} small />
      <MyButton title="<" number onClick={handleBackspace} small />
      {keys.map(key => <MyButton title={key} key={key} number onClick={handleKeyboardClick(key)} small />)}
    </div>
  )
}

export default AmountInput
