import { useRef } from 'react'
import { RouterProps, withRouter } from 'react-router'
import Frame from '../../components/Frame'
import MyButton from '../../components/MyButton'
import __ from '../../components/MyMessage'
import MyModal, { MyModalRef } from '../../components/MyModal'

export interface UserProps extends RouterProps {}

function User (props: UserProps) {
  const logoutRef = useRef<MyModalRef>(null)
  const handleLogout = () => {
    props.history.push('/login')
    localStorage.removeItem('token')
    __('已退出登录！')
  }
  return (
    <Frame title="用户">
      <MyButton title="登出" onClick={() => logoutRef.current?.open()} />
      <MyModal ref={logoutRef} onOk={handleLogout}>
        真的要登出吗？
      </MyModal>
    </Frame>
  )
}

export default withRouter(User)
