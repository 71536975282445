import React from 'react'
import $$ from '../../utils/className'
import styles from './index.module.scss'

export type MyButtonProps = {
  title?: string;
  onClick?: () => void;
  number?: boolean;
  small?: boolean;
}

function MyButton (props: MyButtonProps) {
  const handleClick = () => {
    props.onClick && props.onClick()
  }
  return (
    <div className={styles.whole}>
      <button className={$$([props.number && (styles.number + ' number'), props.small && styles.small])} onClick={handleClick}>{props.title}</button>
    </div>
  )
}

export default MyButton
