import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import Frame from '../../components/Frame'
import MyCheckbox from '../../components/MyCheckbox'
import MyDateInput from '../../components/MyDateInput'
import MyInput from '../../components/MyInput'
import __ from '../../components/MyMessage'
import $$ from '../../utils/className'
import { Bill as BillEntity, BillListQuery } from '../../utils/entity'
import Service from '../../utils/service'
import { S_PER_DAY } from '../../utils/time'
import BillCard from './BillCard'
import styles from './index.module.scss'

function Bill (props: RouteComponentProps<{}, {}, BillListQuery>) {
  const { state } = props.location
  const [bills, setBills] = useState<Array<BillEntity>>([])
  const [total, setTotal] = useState<number>(0)
  const [sum, setSum] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState(!!state)
  const [query, setQuery] = useState<BillListQuery>(state || {})
  const [unconfirmed, setUnconfirmed] = useState(false)
  const getFirstBill = () => {
    setLoading(true)
    Service.bill.list(query).then(res => {
      if (res.success) {
        setBills(res.content.bills)
        setTotal(res.content.total)
        setSum(res.content.sum)
      } else {
        __(res.message)
      }
      setLoading(false)
    })
  }
  useEffect(getFirstBill, [query])
  const getBills = () => {
    if (loading) {
      return
    }
    setLoading(true)
    Service.bill.list({ ...query, skip: bills.length }).then(res => {
      if (res.success) {
        setBills(bills => [...bills, ...res.content.bills])
        setTotal(res.content.total)
        setSum(res.content.sum)
      } else {
        __(res.message)
      }
      setLoading(false)
    })
  }
  const handleUpdate = (id: string) => {
    Service.bill.get(id).then(res => {
      if (res.success) {
        if (res.content.deleted) {
          getFirstBill()
        } else {
          const index = bills.findIndex(bill => bill.id === id)
          setBills(bills => [...bills.slice(0, index), res.content, ...bills.slice(index + 1)])
        }
      } else {
        __(res.message)
      }
    })
  }
  const actions = [
    {
      name: '筛选',
      title: <span className="material-icons" style={filter ? { color: 'var(--primary)' } : {}}>filter_alt</span>,
      onClick: () => {
        if (filter) {
          setFilter(false)
          setQuery({})
        } else {
          setFilter(true)
        }
      }
    }
  ]
  const querySetter = (key: string) => {
    if (key === 'unconfirmed') {
      return (value: any) => {
        setUnconfirmed(value)
        if (value) {
          setQuery({ ...query, prepaid: true, prepay_confirmed: false, canceled: false })
        } else {
          setQuery({ ...query, prepaid: undefined, prepay_confirmed: undefined, canceled: undefined }) 
        }
      } 
    }
    return (value: any) => {
      setQuery({ ...query, [key]: value })
    }
  }
  return (
    <Frame title="账单" className={styles.whole} onScrollEnd={getBills} actions={actions}>
      {filter && <div className={$$([styles.filter, 'border'])}>
        <MyInput small value={query.text} onChange={querySetter('text')} placeholder="包含文本" />
        <div className={styles.date}>
          <MyDateInput value={query.beginTime} onChange={querySetter('beginTime')} />
          ~
          <MyDateInput value={query.endTime && (query.endTime - S_PER_DAY)} onChange={v => setQuery({ ...query, endTime: v + S_PER_DAY })} />
        </div>
        <MyCheckbox value={unconfirmed} onChange={querySetter('unconfirmed')}>未确认</MyCheckbox>
        <div className={styles.statistics}>
          共 {total} 条记录，合计 {sum / 100} 元
        </div>
      </div>}
      <div className={styles.bills}>
        {bills.map(bill => <BillCard key={bill.id} {...bill} onUpdate={handleUpdate} />)}
        {bills.length < total && <div className={styles.more} onClick={getBills}>{loading ? '正在加载...' : '查看更多'}</div>}
      </div>
    </Frame>
  )
}

export default withRouter(Bill)
