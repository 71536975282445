import styles from './App.module.scss';
import Router from './router';

function App() {
  return (
    <div className={styles.whole}>
      <Router />
    </div>
  );
}

export default App;
