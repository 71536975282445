import { useEffect, useState } from 'react'
import Service from '../../../utils/service'
import { ms2s } from '../../../utils/time'
import styles from './index.module.scss'

export type CalendarAmountProps = {
  begin: number;
  end: number;
  day?: number;
  disabled?: boolean;
  short?: boolean;
}

const shorten = (n: string) => {
  const a = n.split('.')[0]
  if (a.length >= 3) {
    return a
  }
  return n.substring(0, 4)
}

function CalendarAmount (props: CalendarAmountProps) {
  const [amount, setAmount] = useState('')
  useEffect(() => {
    setAmount('')
    if (props.disabled) {
      return
    }
    const { begin, end } = ms2s({ begin: props.begin, end: props.end })
    Service.statistic.summary(begin, end).then(res => {
      if (res.success) {
        let amount = (res.content / 100).toFixed(2)
        if (props.short) {
          amount = shorten(amount)
        }
        if (props.day) {
          let average = (res.content / 100 / props.day).toFixed(2)
          if (props.short) {
            average = shorten(average)
          }
          setAmount(`${average} / ${amount}`)
        } else {
          setAmount(amount)
        }
      }
    })
  }, [props])
  return (
    <div className={styles.whole}>
      { amount || (props.disabled ?
        <span className="material-icons">remove</span>
        : <span className="material-icons rotating">autorenew</span>)}
    </div>
  )
}

export default CalendarAmount
