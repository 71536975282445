import { Bill, BillAddable, Tag, TagUpdatable, BillListQuery } from "./entity"
import request, { Response } from "./request"

const Service = {
  user: {
    login: (data: { username: string, password: string }) => request('/user/login', data) as Response<string>,
    register: (data: { username: string, password: string }) => request('/user/register', data),
    info: () => request('/user/info')
  },
  bill: {
    list: (data: BillListQuery = {}) => request(`/bill/list`, data) as Response<{ bills: Array<Bill>, total: number, sum: number }>,
    add: (data: BillAddable) => request('/bill/add', data),
    get: (id: string) => request(`/bill/${id}`) as Response<Bill>,
    confirm: (data: { id: string, true_amount: number }) => request('/bill/confirm', data),
    update: (data: { id: string, true_amount: number }) => request('/bill/update', data),
    cancel: (data: { id: string }) => request('/bill/cancel', data),
    delete: (data: { id: string }) => request('/bill/delete', data)
  },
  tag: {
    list: () => request('/tag/list') as Response<Array<Tag>>,
    update: (data: TagUpdatable) => request('/tag/update', data),
    add: (data: { title: string }) => request('/tag/add', data)
  },
  statistic: {
    summary: (begin: number, end: number) => request(`/statistic/summary/${begin}/${end}`) as Response<number>
  }
}

export default Service
