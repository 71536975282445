import styles from './index.module.scss'
import Calendar from '../../components/Calendar'
import Frame from '../../components/Frame'
import Summary from '../../components/Summary'

function Statistic () {
  return (
    <Frame className={styles.whole} title="统计">
      <Summary />
      <Calendar />
    </Frame>
  )
}

export default Statistic
