import { useState } from 'react'
import { RouterProps, withRouter } from 'react-router-dom'
import MyButton from '../../components/MyButton'
import MyInput from '../../components/MyInput'
import __ from '../../components/MyMessage'
import Service from '../../utils/service'
import styles from './index.module.scss'

function Login (props: RouterProps) {
  const [isLogin, setIsLogin] = useState(true)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [repeat, setRepeat] = useState('')
  const handleToggle = () => {
    setUsername('')
    setPassword('')
    setRepeat('')
    setIsLogin(!isLogin)
  }
  const handleLogin = () => {
    Service.user.login({ username, password }).then(res => {
      if (res.success) {
        window.localStorage.setItem('token', res.content)
        props.history.push('/')
      } else {
        __(res.message)
      }
    })
  }
  const handleRegister = () => {
    if (repeat !== password) {
      __('两次密码输入不一致！')
      return
    }
    Service.user.register({ username, password }).then(res => {
      if (res.success) {
        handleToggle()
        __('注册成功！')
      } else {
        __(res.message)
      }
    })
  }
  return (
    <div className={styles.whole}>
      <div className={styles.title}>没钱</div>
      <MyInput placeholder="账号" small value={username} onChange={setUsername} />
      <MyInput placeholder="密码" type="password" small value={password} onChange={setPassword} />
      {isLogin ? <MyButton title="登 录" onClick={handleLogin} /> : <>
        <MyInput placeholder="重复密码" type="password" small value={repeat} onChange={setRepeat} />
        <MyButton title="注 册" onClick={handleRegister} />
      </>}
      <div className={styles.toggle} onClick={handleToggle}>
        {isLogin ? '还没有账号？注册一个！' : '已有账号？去登录！'}
      </div>
    </div>
  )
}

export default withRouter(Login)
