import styles from './index.module.scss'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Fragment, useState } from 'react'
import $$ from '../../utils/className'
import MyButton from '../MyButton'
import { CHINESE_DAYS, getDayOfWeek, getDaysOfMonth, MS_PER_DAY } from '../../utils/time'
import CalendarEntry from './CalendarEntry'
import history from '../../utils/history'
import CalendarAmount from './CalendarAmount'

export interface CalendarProps extends RouteComponentProps {}

const WEEKS = (() => {
  const weeks = []
  for (let i = 0; i < 6; ++i) {
    weeks.push(Array(7).fill(0).map((_, index) => (i * 7 + index)))
  }
  return weeks
})()

function Calendar (props: CalendarProps) {
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const dateString = `${year}/${month}/1`
  const date = new Date(dateString)
  const dayOfWeek = getDayOfWeek(dateString)
  const daysOfMonth = getDaysOfMonth(year, month)
  const beginTimestamp = date.getTime()
  const endTimestamp = date.getTime() + daysOfMonth * MS_PER_DAY
  const currentTimestamp = new Date().getTime()
  const averageDay = (currentTimestamp >= beginTimestamp && currentTimestamp < endTimestamp) ? new Date().getDate() : daysOfMonth
  const handleChangeMonth = (offset: number) => {
    return () => {
      let nextMonth = month + offset
      let nextYear = year
      if (nextMonth > 12) {
        nextYear++
        nextMonth -= 12
      }
      if (nextMonth < 1) {
        nextYear--
        nextMonth += 12
      }
      setYear(nextYear)
      setMonth(nextMonth)
    }
  }
  const handleClickDate = (begin: number, end: number) => {
    history.push('/bill', {
      beginTime: begin / 1000 | 0,
      endTime: end / 1000 | 0,
      order: 1
    })
  }
  return (
    <div className={$$([styles.whole, 'border'])}>
      <div className={styles.header}>
        <div className={styles.month} onClick={() => handleClickDate(beginTimestamp, endTimestamp)}>
          <div className={styles.date}>
            {`${year} 年 ${month} 月`}
          </div>
          <CalendarAmount begin={beginTimestamp} end={endTimestamp} day={averageDay} />
        </div>
        <div className={styles.control}>
          <MyButton title="<" small onClick={handleChangeMonth(-1)} />
          <MyButton title=">" small onClick={handleChangeMonth(1)} />
        </div>
      </div>
      <div className={styles.days}>
        {CHINESE_DAYS.map(day => (
          <div className={styles.day} key={day}>
            { day }
          </div>
        ))}
        {WEEKS.map(week => {
          const offset = week[0] - dayOfWeek
          return <Fragment key={week.toString()}>
            <CalendarEntry base={beginTimestamp} offset={offset} week onClick={handleClickDate} />
            {week.map(day => {
              const offset = day - dayOfWeek
              return <CalendarEntry key={day} base={beginTimestamp} offset={offset} onClick={handleClickDate} />
            })}
          </Fragment>
        })}
      </div>
    </div>
  )
}

export default withRouter(Calendar)
