import React, { useEffect, useRef, useState } from 'react'
import $$ from '../../utils/className'
import { Tag } from '../../utils/entity'
import { Body } from '../../utils/request'
import Service from '../../utils/service'
import MyInput from '../MyInput'
import __ from '../MyMessage'
import MyModal, { MyModalRef } from '../MyModal'
import styles from './index.module.scss'

export type TagInputProps = {
  onChange?: (tags: Array<string>) => void;
}

function TagInput (props: TagInputProps) {
  const [tags, setTags] = useState<Array<Tag>>([])
  const [selected, setSelected] = useState<Array<string>>([])
  const [editing, setEditing] = useState(false)
  const [editId, setEditId] = useState('')
  const [editTitle, setEditTitle] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [deleteTag, setDeleteTag] = useState('')
  const tagTitleRef = useRef<MyModalRef>(null)
  const tagDeleteRef = useRef<MyModalRef>(null)
  const [touchId, setTouchId] = useState<any>(0)
  const getTags = () => {
    Service.tag.list().then(res => {
      if (res.success) {
        setTags(res.content)
        setSelected([])
      } else {
        __(res.message)
      }
    })
  }
  const afterUpdate = (res: Body) => {
    if (res.success) {
      getTags()
    } else {
      __(res.message)
    }
  }
  useEffect(getTags, [])
  const getTitleById = (id: string) => {
    return tags.find(tag => tag.id === id)?.title as string
  }
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const id = (e.target as HTMLDivElement).dataset.id
    if (!id) {
      return
    }
    const start = selected.indexOf(id)
    if (editing) {
      setEditId(id)
      setEditTitle(getTitleById(id))
      tagTitleRef.current?.open()
    } else {
      const newSelected = start >= 0 ? selected.slice(0, start).concat(selected.slice(start + 1)) : [...selected, id]
      setSelected(newSelected)
      const titles = newSelected.map(id => getTitleById(id))
      props.onChange && props.onChange(titles)
    }
  }
  const handleToggleEdit = () => {
    if (editing) {
      setEditing(false)
    } else {
      setSelected([])
      setEditing(true)
      props.onChange && props.onChange([])
    }
  }
  const handleShowAddTag = () => {
    setEditId('')
    setEditTitle('')
    tagTitleRef.current?.open()
  }
  const handleTagTitleOk = () => {
    const title = editTitle.trim()
    if (!title) {
      __('标签名不能为空！')
      return false
    }
    if (editId) {
      Service.tag.update({ id: editId, title }).then(afterUpdate)
    } else {
      Service.tag.add({ title }).then(afterUpdate)
    }
  }
  const handleShowDeleteTag = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    const id = (e.target as HTMLDivElement).dataset.id
    if (!id) {
      return
    }
    setDeleteId(id)
    setDeleteTag(getTitleById(id))
    tagDeleteRef.current?.open()
  }
  const handleDeleteTag = () => {
    Service.tag.update({ id: deleteId, valid: false }).then(afterUpdate)
  }
  const handleTouchStart = (e: any) => {
    if (!editing) {
      return
    }
    const { id } = e.target.dataset
    if (!id) {
      return
    }
    setTouchId(setTimeout(() => {
      Service.tag.update({ id, tap_time: 1 }).then(afterUpdate)
    }, 400))
  }
  const handleTouchEnd = () => {
    if (touchId) {
      clearTimeout(touchId)
    }
  }
  return (
    <div className={styles.whole} onClick={handleClick} onMouseDown={handleTouchStart} onMouseUp={handleTouchEnd} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      {tags.length === 0 && <div className={styles.tag}>还没有可选的标签～</div>}
      {tags.length > 0 && tags.map(tag => (
        <div className={$$([styles.tag, selected.includes(tag.id) && styles.selected, editing && styles.editing])} key={tag.id} data-id={tag.id}>
          {tag.title}
          {editing && <div className={styles.delete} onClick={handleShowDeleteTag}>
            <span className="material-icons" data-id={tag.id}>
              remove_circle
            </span>
          </div>}
        </div>
      ))}
      <div className={$$([styles.tag, styles.control, editing && styles.selected])} onClick={handleToggleEdit}>
        <span className="material-icons">
          edit
        </span>
      </div>
      {editing && <div className={$$([styles.tag, styles.control, editing && styles.selected])} onClick={handleShowAddTag}>
        <span className="material-icons">
          plus_one
        </span>
      </div>}
      <MyModal ref={tagTitleRef} onOk={handleTagTitleOk}>
        <div>请输入标签名</div>
        <MyInput value={editTitle} onChange={setEditTitle} small />
      </MyModal>
      <MyModal ref={tagDeleteRef} onOk={handleDeleteTag}>
        真的要删除“{deleteTag}”这个标签嘛？
      </MyModal>
    </div>
  )
}

export default TagInput
