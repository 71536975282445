import { useEffect, useState } from 'react'
import $$ from '../../utils/className'
import Service from '../../utils/service'
import { today, currentMonth, currentWeek, yesterday, lastMonth, lastWeek } from '../../utils/time'
import styles from './index.module.scss'

export type SummaryDate = 'today' | 'yesterday' | 'this_week' | 'last_week' | 'this_month' | 'last_month'

export const SummaryDateList: Array<SummaryDate> = ['today', 'yesterday', 'this_week', 'last_week', 'this_month', 'last_month']

export const DateName = {
  'today': '今日',
  'yesterday': '昨日',
  'this_week': '本周',
  'last_week': '上周',
  'this_month': '本月',
  'last_month': '上月',
}

export const DateFunction = {
  'today': today,
  'yesterday': yesterday,
  'this_week': currentWeek,
  'last_week': lastWeek,
  'this_month': currentMonth,
  'last_month': lastMonth
}

export type DateData = {
  date: string;
  data: string;
}

export type SummaryProps = {
  value?: SummaryDate[];
  onClick?: () => void;
}

function Summary (props: SummaryProps) {
  const [data, setData] = useState<Array<DateData>>([])
  useEffect(() => {
    const value = props.value || SummaryDateList
    const data = value.map(date => ({ date: DateName[date], data: '' }))
    setData(data)
    value.forEach((date, index) => {
      const { begin, end } = DateFunction[date]()
      Service.statistic.summary(begin, end).then(res => {
        if (res.success) {
          data[index].data = (res.content / 100).toFixed(2)
          setData([...data])
        }
      })
    })
  }, [props.value])
  const handleClick = () => {
    props.onClick && props.onClick()
  }
  return (
    <div className={$$([styles.whole, 'border'])} onClick={handleClick}>
      {data.map(v => (
        <div className={styles.block} key={v.date}>
          <div className={styles.time}>
            {v.date}
          </div>
          <div className={styles.amount}>
            {v.data || <span className="material-icons rotating">autorenew</span>}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Summary
